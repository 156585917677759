// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "select2/dist/js/select2.min";

$(document).ready(function () {
  $(".select2-field").select2();
});

$(document).ready(function () {
  $('[data-open-doc]').on('click', function(){
    docID = $(this).data('open-doc');
    $.ajax({
      type: 'POST',
      url: '/documents/downloaded',
      data: {
        doc_id: docID
      }
    });
  });

  if ($('.login-page').length > 0) {
    $('#footer').remove();
  }

  $('.access-to-login').on('click', function(){
    $('.login-modal').toggleClass('active')
    $('body').toggleClass('no-scroll')
  });
});